import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import '../../../assets/css/modal.css'
import './TCModal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import { pushCleverTapEvent } from '../../../helper/clevertapFunction';

const TCModal = ({ showTCModal, setShowTCModal, showRefundModal, setShowRefundModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  
  function hideModalOperation() {
    setShowTCModal(false)
  }

  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("termsConditions", msisdn);
    analyticEvent('termsConditions', 'termsConditions-view')
    pushCleverTapEvent('CheckIn-TC');
  }, [])

  return (
    <>
      <Modal show={showTCModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>শর্তাবলী</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll", display: 'block', overflowX: 'hidden' }}>
          <>
            <p>QUIZARD কুইজ গেমে আপনাকে স্বাগতম ! QUIZARD এ কুইজ খেলে দৈনিক, সাপ্তাহিক, টুর্নামেন্ট ভিত্তিক আকর্ষণীয় পুরস্কার জিতে নিন । পাশাপাশি নিজের সাধারণ জ্ঞান ভিত্তিক দক্ষতা ও যাচাই করে ফেলুন । খেলা শুরু করার আগে, অনুগ্রহ করে আপনি নিচের নিয়ম ও শর্তাবলী মনোযোগ সহকারে পড়ুন এবং বুঝুন । এই কুইজ খেলায় অংশগ্রহণ করে, আপনি নিম্নলিখিত শর্তাবলী মেনে চলতে সম্মত হোন।</p>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>খেলার যোগ্যতা</Accordion.Header>
                <Accordion.Body>
                  QUIZARD কুইজ গেমটি সকল বয়সের এবং জাতীয়তার বাক্তিদের জন্য উন্মুক্ত । কিন্তু, যে কোনো ধরনের প্রতারণা, বাহ্যিক সাহায্যের ব্যবহার, বা কুইজ প্ল্যাটফর্মের সাথে হেরফের করার প্রচেষ্টা অবিলম্বে অযোগ্যতার কারণ হবে। অযোগ্য অংশগ্রহণকারীরা কোনো পুরস্কার বা পুরস্কারের জন্য যোগ্য হবে না। আয়োজক সত্তার কর্মচারী এবং পরিবারের সদস্যরা অংশগ্রহণের যোগ্য নন।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>লিংক</Accordion.Header>
                <Accordion.Body>
                  <a href="https://www.quizard.live/" target="_blank" rel="noopener noreferrer">https://www.quizard.live/</a>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>কীভাবে যোগদান করবেন</Accordion.Header>
                <Accordion.Body>
                  সাপ্তাহিক ৩০ টাকা চার্জে কুইজ প্রতিদিনে এবং সাপ্তাহিক ৩০ টাকা চার্জে টুর্নামেন্ট ভিত্তিক কুইজ গুলো তে আপনি কুইজ খেলতে পারবেন | বিকাশ পেমেন্ট এর মাধ্যমে ব্যবহারকারীরা তাদের অ্যাকাউন্টটির সাবস্ক্রিপশন সম্পন্ন করতে পারবেন আর QUIZARD এ যোগদান করতে পারবেন।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>কুইজ খেলার নিয়ম</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>QUIZARD কুইজ গেমটিতে কুইজ প্রতিদিন, একাধিক টুর্নামেন্ট কুইজ এর পাশাপাশি ক্যাটাগরি কুইজের একাধিক সেগমেন্টে কুইজ খেলার সুযোগ থাকবে।</li>
                    <li>QUIZARD এর প্রতিটি সেগমেন্টে সর্বোচ্চ ৬০ টি পছন্দের প্রশ্ন থাকবে এবং দৈনিক ২ বার খেলা যাবে।</li>
                    <li>প্রতি রাউন্ড এ অংশগ্রহণকারীদের প্রদত্ত বিকল্পগুলি থেকে সঠিক উত্তর নির্বাচন করতে হবে। অংশগ্রহণকারীরা প্রতিটি সঠিক উত্তরের জন্য ১ পয়েন্ট অর্জন করবে।</li>
                    <li>প্রতিটি প্রশ্নের কাঠিন্য অনুযায়ী উত্তর জমা দেওয়ার জন্য একটি নির্দিষ্ট সময়সীমা থাকবে। নির্ধারিত সময়ের মধ্যে উত্তর দিতে ব্যর্থ হলে সেই নির্দিষ্ট প্রশ্নের জন্য অযোগ্য ঘোষণা করা হবে।</li>
                    <li>গেমের শুরুতে স্কোরিং সিস্টেম প্রদান করা হবে এবং প্রশ্নের জটিলতার উপর নির্ভর করে এটি পরিবর্তিত হতে পারে।</li>
                    <li>দুটি রাউন্ড এর মধ্যে যে রাউন্ড এর স্কোর বেশি হবে, সেটিই ফাইনাল স্কোর হিসেবে গণনা করা হবে।</li>
                    <li>ক্যাটাগরি কুইজ খেলার জন্য কোনো সাবস্ক্রিপশন দরকার হবে না। প্রাইজ প্রযোজ্য নয়।</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>বিজয়ীদের পুরস্কার</Accordion.Header>
                <Accordion.Body>
                  QUIZARD কর্তৃপক্ষ বিজয়ীদের দৈনিক, সাপ্তাহিক, টুর্নামেন্ট ভিত্তিক পুরস্কার প্রদান করে থাকে।<br /><br />
                  <b>দৈনিক পুরস্কার :</b> প্রতিদিনের অংশগ্রহণকারীদের মধ্যে সেরা ৩০ সঠিক উত্তর প্রদানকারী কে ২০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন করা হবে | দৈনিক পুরস্কারের সর্বোচ্চ লিমিট : প্রথম ৩০ জন ।<br /><br />
                  <b>সাপ্তাহিক পুরস্কার :</b> প্রতি সপ্তাহের টুর্নামেন্ট অংশগ্রহণকারীদের মধ্যে সেরা ৩ সঠিক উত্তর প্রদানকারী কে ১০০০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন করা হবে|
                  <ul>
                    <li>প্রথম পুরস্কার : ৫০০ টাকা</li>
                    <li>দ্বিতীয় পুরস্কার : ৩০০ টাকা</li>
                    <li>তৃতীয় পুরস্কার : ২০০ টাকা</li>
                  </ul>
                  <b>টুর্নামেন্ট ভিত্তিক পুরস্কার :</b> প্রতিটি টুর্নামেন্ট শেষে সেরা ৩ জন পর্যন্ত পাবেন ১০ হাজার টাকার সমতুল্য মেগা পুরস্কার - গ্যাজেট অথবা গিফট কার্ড (মেধাক্রম অনুসারে)।<br /><br />
                  ** আয়োজক সত্তা তার বিবেচনার ভিত্তিতে পুরস্কার পরিবর্তন বা প্রতিস্থাপন করার অধিকার সংরক্ষণ করে।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>কুইজ প্রতিদিন এর বিজয়ী হবার নিয়মাবলী</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>তুলনামূলক কম সময়ে সর্বাধিক সঠিক উত্তর প্রদানকারী দের মধ্যে মেধাক্রম অনুসারে প্রথম ৩০ জন পর্যন্ত পুরস্কৃত করা হবে।</li>
                    <li>দৈনিক অংশগ্রহণকারীদের মধ্যে সেরা ৩০ সঠিক উত্তর প্রদানকারী কে বিজয়ী হিসেবে বিবেচিত করা হবে।</li>
                    <li>কুইজ প্রতিদিন এ বিজয়ী হওয়ার জন্য অংশগ্রহণকারীকে পেইড সাবস্ক্রাইবার হতে হবে।</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>টুর্নামেন্ট এর বিজয়ী হবার নিয়মাবলী</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>প্রতিটি টুর্নামেন্ট শেষে অংশগ্রহণকারীদের টোটাল স্কোর ও সময় গণনা করা হবে।</li>
                    <li>সর্বনিম্ন সময়ে সর্বোচ্চ স্কোর ধারী ৩ জন কে মেগা পুরস্কার প্রদান করা হবে।</li>
                    <li>টুর্নামেন্ট এ বিজয়ী হওয়ার জন্য টুর্নামেন্ট চলাকালীন সময় একজন অংশগ্রহণকারী QUIZARD থেকে আনসাবস্ক্রাইব করতে পারবে না। অন্যথায় সর্বোচ্চ স্কোর থাকলেও অযোগ্য হিসেবে ধরা হবে এবং কোনো পুরস্কারের জন্য যোগ্য বিবেচিত হবে না।</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>রেফার কোড ব্যবহারের নিয়ম</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>QUIZARD এর মেন্যু বার থেকে Profile (প্রোফাইল) অপশনে গেলে আপনার রেফার কোড টি পেয়ে যাবেন ।</li>
                    <li>আপনার রেফারেল কোডটি শেয়ার করুন । আপনার রেফার কোড ব্যবহার করে কেউ যদি সাবস্ক্রিপশন করে, তাহলে প্রতি সফল সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকা ক্যাশ ইন।</li>
                    <li>কারো শেয়ারকৃত রেফার কোড ব্যবহার করে সাবস্ক্রিপশন করলে, ১ম সাবস্ক্রিপশনে আপনি পেয়ে যাবেন ১০ টাকার ক্যাশ ব্যাক।</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>ক্যাম্পেইন এর সময়সীমা</Accordion.Header>
                <Accordion.Body>
                  প্রতি বৃহস্পতিবার থেকে বুধবার পর্যন্ত।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9">
                <Accordion.Header>গোপনীয়তা</Accordion.Header>
                <Accordion.Body>
                  কুইজের সময় সংগৃহীত অংশগ্রহণকারীদের তথ্য শুধুমাত্র খেলা পরিচালনার উদ্দেশ্যে এবং প্রযোজ্য হলে পুরস্কার প্রদানের উদ্দেশ্যে ব্যবহার করা হবে। ব্যক্তিগত তথ্য পূর্ব সম্মতি ছাড়া তৃতীয় পক্ষের সাথে ভাগ করা হবে না।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10">
                <Accordion.Header>বুদ্ধিবৃত্তিক সম্পত্তি</Accordion.Header>
                <Accordion.Body>
                  প্রশ্ন, উত্তর এবং ছবি সহ সমস্ত কুইজের বিষয়বস্তু হল আয়োজক সত্তার বৌদ্ধিক সম্পত্তি। অননুমোদিত প্রজনন বা বিতরণ নিষিদ্ধ।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="11">
                <Accordion.Header>দায়</Accordion.Header>
                <Accordion.Body>
                  কুইজের সময় ঘটতে পারে এমন কোনও প্রযুক্তিগত সমস্যা, বাধা বা ত্রুটির জন্য আয়োজক সত্তা দায়বদ্ধ নয়। অংশগ্রহণকারীরা যেন তাদের নিজস্ব ঝুঁকিতে খেলে।
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TCModal