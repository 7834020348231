import React, { useContext, useEffect, useRef, useState } from "react";


import "./BannerPart3.css";
import axios from "axios";
import { baseUrl, categoryEventId, dataContext, portal } from "../../App";
import { useNavigate } from "react-router-dom";
import activityLogSubmission from "../../helper/activitylog";
import RotatingBox from "./RotatingBox";
import analyticEvent from "../../helper/gaEvent";
import clevertap from "clevertap-web-sdk";
import BannerCategory from "./BannerCategory";
import CategoryBox from "./categoryBox/categoryBox";
import crawlText from './referAssets/text.png'
import referImage from './referAssets/referral.png'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { Draggable } from 'gsap/Draggable';
gsap.registerPlugin(Draggable);
const BannerPart3 = ({ props }) => {
  const referImageRef = useRef(null);
  const navigate = useNavigate();
  const { token, setToken, playerData, setPlayerData, events, setEvents } =
    useContext(dataContext);
  const { showLoginModal, setShowLoginModal, showLoadingModal, setShowLoadingModal, showRedeemModal, setShowRedeemModal, setShowReferModal,setActiveBottom,homePageBodyRef } = props;

  async function quizClickHandler(eventId) {
    console.log(playerData)
    if (!(playerData?.id)) {
      setShowLoginModal(true);
      sessionStorage.setItem("login_event_id", eventId); 
    }else if(categoryEventId.includes(eventId)){
      // Category-International, Category-Sports, Category-Science and Technology, Category-General Knowledge, Category-Bangladesh
      if(eventId===42){clevertap.event.push("Category-International");}
      if(eventId===43){clevertap.event.push("Category-Sports");}
      if(eventId===44){clevertap.event.push("Category-Bangladesh");}
      if(eventId===46){clevertap.event.push("Category-General Knowledge");}
      if(eventId===76){clevertap.event.push("Category-Science and Technology");}
      navigate(`/quiz/?eventId=${eventId}`, { replace: true });
    }
    // eventid 34 daily event
    else if (eventId === 34) {
      if (playerData?.isSubscribe) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        if (playerData?.redeemed_code) {
          setShowLoadingModal(true)
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          axios
            .post(
              `${baseUrl}/subscription/create/`,
              { portal: portal, qcid: sessionStorage.getItem("cid"), "service_type": "Daily" },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowLoadingModal(false)
              // console.log(data);
              if (data?.redirectURL) {
                // analyticEvent('redirect', 'redirect-bkash-payment')
                console.log(`${data?.redirectURL}`);
                // window.location.replace(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
                // return data?.redirectURL
              }
            });
        } else {
          sessionStorage.setItem('clicked_on', 'Daily');
          setShowRedeemModal(true)
        }
      }
    } else {

      if (playerData?.isSubscribeTournament) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        if (playerData?.redeemed_code) {
          setShowLoadingModal(true)
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          axios
            .post(
              `${baseUrl}/subscription/create/`,
              { portal: portal, qcid: sessionStorage.getItem("cid"), "service_type": "Tournament" },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowLoadingModal(false)
              // console.log(data);
              if (data?.redirectURL) {
                // analyticEvent('redirect', 'redirect-bkash-payment')
                console.log(`${data?.redirectURL}`);
                // window.location.replace(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
                // return data?.redirectURL
              }
            });
        } else {
          sessionStorage.setItem('clicked_on', 'Tournament');
          setShowRedeemModal(true)
        }
      }
    }
  }
  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("home", msisdn);
    analyticEvent('home', 'home-view')
    clevertap.event.push("Product viewed");
    gsap.to(referImageRef.current,{display:'block',opacity:1,delay:3,x:0,zIndex:78,duration:1}); // <-- automatically reverted
    Draggable.create(referImageRef.current, {
      type: 'x,y', // or you can use 'rotation' for rotating elements
      edgeResistance: 0.65,
      bounds: homePageBodyRef.current, // or a specific container
      allowEventDefault: true,
      inertia: true,
    });
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
      };
    } else {
      return {
        eventTitle: event.events,
        score: "-",
      };
    }
  });
  // console.log(userScore);
  let userJsx = ``;
  for (let i = 0; i < userScore.length; i++) {
    if (i == 0) {
      userJsx = `<div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    } else {
      userJsx += `
    <div className="horizontalBar"></div>
    <div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    }
  }
  return (
    <>
      <div className="header-img-div">
        {/* <img src={playerCard} className="header-img" alt="" srcSet="" /> */}
        <div className="bannerAvatar-div">
          <img
            src={
              playerData?.avatar_img
                ? `${baseUrl}${playerData?.avatar_img}`
                : require(`../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                  }.png`)
            }
            className="banner-avatarImg"
            alt=""
            srcSet=""
          />
        </div>

        <div className="userEventScore">
          {/* {userJsx} */}
          {userScore.map((singleUserScore, index) => {
            if (index == 0) {
              return (
                <div className="singleEventScore" key={index}>
                  <div>{singleUserScore.eventTitle}</div>
                  <div>{singleUserScore.score}</div>
                </div>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <div className="horizontalBar"></div>
                  <div className="singleEventScore">
                    <div>{singleUserScore.eventTitle}</div>
                    <div>{singleUserScore.score}</div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
      <div className="bannerDiv">
        {/* <RotatingBox props={{ events,quizClickHandler }}></RotatingBox> */}
        <div style={{ color: '#4501a3', fontWeight: '600', fontSize: '2.2dvh', zIndex: 77 }}>কুইজ প্রতিদিন</div>
        <div className="dailyQuizBanner" style={{width:'100%', zIndex:19}}>
          <img src={events[0]?.banner_url} style={{width:'100%'}} onClick={()=>{quizClickHandler(events[0]?.id)}} alt="" />
        </div>
        <div style={{ color: '#4501a3', fontWeight: '600', fontSize: '2.2dvh', zIndex: 77 }}>কুইজ টুর্নামেন্ট</div>
        {events.slice(1).map((event, index) => (
          <div key={index} className="tournamentQuizBanner" style={{width:'100%', zIndex:19}}>
            <img 
              src={event.banner_url} 
              style={{width:'100%'}} 
              onClick={() => quizClickHandler(event.id)} 
              alt="" 
            />
          </div>
        ))}
        {/* <BannerCategory propsN={{ events, quizClickHandler }}></BannerCategory> */}
        <div className="categoryQuizText">ক্যাটাগরি কুইজ</div>
        <CategoryBox props={{ events, quizClickHandler }} />
        {/* <div style={{ zIndex: 9, color: 'red', textAlign: 'center', textDecoration: 'underline' }} onClick={() => { setShowReferModal(true) }}>রেফার করুন, ক্যাশব্যাক জিতুন</div> */}

        <img src={referImage} className="referImage" alt="" ref={referImageRef} onClick={() => { setActiveBottom('referral') }} />
      </div>
    </>
  );
};

export default BannerPart3;
