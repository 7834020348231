import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import './RewardsModal.css'
import Accordion from 'react-bootstrap/Accordion';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import reward1 from '../../../assets/rewards/1.png'
import reward2 from '../../../assets/rewards/2.png'
import reward3 from '../../../assets/rewards/3.png'

// import './App.css';

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import { pushCleverTapEvent } from '../../../helper/clevertapFunction';

const RewardsModal = ({ showRewardsModal, setShowRewardsModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  function hideModalOperation() {
    setShowRewardsModal(false)
  }
  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("rewards", msisdn);
    analyticEvent('rewards', 'rewards-view')
    pushCleverTapEvent('CheckIn-Prize');
  }, [])
  return (
    <>
      <Modal show={showRewardsModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>প্রাইজ</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll", display: 'block', overflowX: 'hidden' }}>
          <>
            <p>QUIZARD কুইজ গেমে আপনাকে স্বাগতম ! </p>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>কুইজ প্রতিদিনের প্রাইজ</Accordion.Header>
                <Accordion.Body>
                  কুইজ প্রতিদিনে অংশগ্রহণকারীদের মধ্যে টপ ৩০ জন স্কোরার পাবেন ২০ টাকা ক্যাশ ইন প্রাইজ।
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>সাপ্তাহিক কুইজ প্রাইজ</Accordion.Header>
                <Accordion.Body>
                  টুর্নামেন্ট কুইজে প্রতি সপ্তাহে অংশগ্রহণকারীদের মধ্যে টপ ৩ জন স্কোরার পাবেন ১০০০ টাকা পর্যন্ত বিকাশ ক্যাশ ইন করা হবে।
                  <ul>
                    <li>প্রথম পুরস্কার: ৫০০ টাকা</li>
                    <li>দ্বিতীয় পুরস্কার: ৩০০ টাকা</li>
                    <li>তৃতীয় পুরস্কার: ২০০ টাকা</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>টুর্নামেন্ট প্রাইজ</Accordion.Header>
                <Accordion.Body>
                  প্রতিটি টুর্নামেন্ট শেষে সেরা ৩ জন পর্যন্ত পাবেন ১০ হাজার টাকার সমতুল্য মেগা পুরস্কার - গ্যাজেট অথবা গিফট কার্ড (মেধাক্রম অনুসারে)।
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RewardsModal