import React, { useContext, useEffect, useState } from 'react'
import '../../../assets/css/modal.css'
import './ResultModal.css'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { pushCleverTapEvent } from '../../../helper/clevertapFunction';
const ResultModal = ({ showResultModal, setShowResultModal, finalScore }) => {
  useEffect(() => {
    if(sessionStorage.getItem('played_eventId') == 34){pushCleverTapEvent('CompletePlay-QuizPratidin')}
    else if(sessionStorage.getItem('played_eventId') == 75){pushCleverTapEvent('CompletePlay-SportsQuiz')}
    else if(sessionStorage.getItem('played_eventId') == 77){pushCleverTapEvent('CompletePlay-WinterQuiz')}
    sessionStorage.removeItem('played_eventId');
  }, [])
  const navigate = useNavigate()
  function hideModalOperation() {
    setShowResultModal(false)
    navigate(`../`, { replace: true })
  }
  return (
    <>
      <Modal show={showResultModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>Score 😊</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center fs-5">Your Score</div>
          <div className="finalScore text-center fs-4">{finalScore}</div>
          <div className="allBtn d-flex justify-content-center w-90 gap-4 mt-3">
            <div className="btn btn-light modalButtonIcon" onClick={() => { navigate(`../?page=home`, { replace: true }); }}><i className="fa fa-home fa-fw"></i></div>
            <div className="btn btn-light modalButtonIcon" onClick={() => { navigate(`../?page=leader`, { replace: true }); }}><i className="fa-solid fa-medal"></i></div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default ResultModal